<template>
  <div class="">
    <v-data-table
      :headers="headers"
      :items="value"
      hide-default-footer
    >
      <template v-slot:[`item.job_type`]="{ item }">
        <div>
          <v-select
            v-model="item.job_type"
            dense
            :label="$t('job_type_*')"
            :items="['pick', 'drop', 'others']"
            :rules="[rules.required]"
            @change="onChangeField"
          />
          <!-- :rules="[rules.required, jobTypeValidate(index)]" -->
        </div>
      </template>
      <template v-slot:[`item.client`]="{ item }">
        <div>
          <v-select
            v-model="item.client"
            dense
            :items="clientList"
            item-text="client_name"
            item-value="id"
            :label="$t('select_client_*')"
            :rules="[rules.required]"
          />
        </div>
      </template>
      <template v-slot:[`item.location`]="{ item }">
        <div>
          <v-select
            v-model="item.location"
            dense
            :label="$t('select_location_*')"
            :items="getLocations(item.client)"
            :item-value="'id'"
            :item-text="'location_name'"
            :rules="[rules.required]"
            @change="onChangeField"
          />
        </div>
      </template>

      <template v-slot:[`item.stop_duration`]="{ item }">
        <div>
          <!-- :label="$t('stop_duration_*')" -->
          <v-select
            v-model="item.stop_duration"
            dense
            label="(MIN) *"
            :items="[10, 20, 30, 40, 50, 60]"
            :rules="[rules.required]"
            @change="onChangeField"
          />
        </div>
      </template>
      <template v-slot:[`item.time_window`]="{ item }">
        <div class="d-flex gap">
          <v-text-field
            v-model="item.time_window_start"
            dense
            type="time"
            name="time_window_start"
            min="09:00"
            max="18:00"
            @change="onChangeField"
          />
          <v-text-field
            v-model="item.time_window_end"
            dense
            type="time"
            min="12:25"
            :rules="[timeWindowValidation(item)]"
            @change="onChangeField"
          />
          <div
            v-if="item.time_window_start || item.time_window_end"
            class="timeWindow-clear"
            @click="timeWindowClear(item)"
          >
            X
          </div>
        </div>
      </template>
      <template v-slot:[`item.notes`]="{ item }">
        <div>
          <v-text-field
            v-model="item.job_notes"
            dense
            :label="$t('notes')"
            @change="onChangeField"
          />
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item, index }">
        <div class="d-flex align-center gap">
          <v-btn
            v-if="value?.length == index + 1"
            color="primary"
            class="mr-1"
            dark
            x-small
            @click="() => value.push({})"
          >
            <v-icon small>
              mdi-plus
            </v-icon>
          </v-btn>
          <v-btn
            v-if="value?.length > 1"
            color="danger"
            dark
            x-small
            @click="deleteStop(item)"
          >
            <v-icon small>
              mdi-delete
            </v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>

    <!--  -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { validationRules } from "@/mixins/validationRules";
export default {
  components: {},
  mixins: [validationRules],

  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    type: {
      type: String,
      default() {
        return "";
      },
    },
    items: {
      type: Object,
      default() {
        return {};
      },
    },
    locations: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      headers: [
        {
          text: this.$t("job_type"),
          align: "left",
          sortable: false,
          value: "job_type",
          width: "10%",
        },
        {
          text: this.$t("client"),
          align: "left",
          sortable: false,
          value: "client",
          width: "20%",
        },
        {
          text: this.$t("location"),
          align: "left",
          sortable: false,
          value: "location",
          width: "20%",
        },

        {
          text: this.$t("stop"),
          align: "left",
          sortable: false,
          value: "stop_duration",
          width: "10%",
        },
        {
          text: this.$t("time_window"),
          align: "left",
          sortable: false,
          value: "time_window",
          width: "10%",
        },
        {
          text: this.$t("notes"),
          align: "left",
          sortable: false,
          value: "notes",
          width: "35%",
        },
        {
          text: this.$t("actions"),
          align: "left",
          sortable: false,
          value: "actions",
          // width: "15%",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      clientList: "routePlaning/getClientList",
    }),
  },
  mounted() {
    // if (this.type === "create") {
    // this.value.push({});
    // this.value.push({
    //   client_id: "asd",
    //   pick_up_id: "asd",
    //   drop_off_id: "asd",
    // });
    // }
  },
  methods: {
    onChangeField() {
      // client: this.items?.client,
      const params = this.value.map((r) => ({
        ...r,
        status: "pending",
        crew: null,
        time_window: `${this.formatTime(
          r?.time_window_start
        )} - ${this.formatTime(r?.time_window_end)}`,
      }));
      this.$emit("input", params);
    },

    //
    getLocations(val) {
      const arr = this.clientList.find((r) => val === r.id);
      return arr?.client_location;
    },
    //
    formatTime(time24) {
      if (time24) {
        // Split the time string into hours and minutes
        const [hours24, minutes] = time24.split(":").map(Number);
        // Determine AM/PM and convert hours
        const isPM = hours24 >= 12;
        const hours12 = hours24 % 12 || 12; // Convert to 12-hour format, 0 hour should be 12
        const period = isPM ? "PM" : "AM";
        // Format the minutes to always be two digits
        const formattedMinutes = minutes.toString().padStart(2, "0");

        // Return the formatted time string
        return `${hours12}:${formattedMinutes} ${period}`;
      } else return "";
    },
    timeWindowClear(row) {
      row.time_window_start = null;
      row.time_window_end = null;
      row.time_window = null;
    },
    // checkPrevious(v) {
    //   const ids = [...this.value];
    //   if (this.items?.start_location_id) {
    //     ids.push(this.items?.start_location_id);
    //   }

    //   return ids.includes(v.id);
    // },
    deleteStop(v) {
      const index = this.value.findIndex((r) => r == v);
      const arr = this.value.filter((r, i) => i !== index);
      this.$emit("input", arr);
    },

    jobTypeValidate(i) {
      const len = this.value.length - 1;
      if (i == len) {
        const lastRow = this.value[len];
        return lastRow.job_type == "pick" ? "should be add Drop" : true;
      }
      return true;
    },
    timeWindowValidation(val) {
      if (val?.time_window_start) {
        const errMsg = `Greater than ${this.formatTime(val.time_window_start)}`;
        if (val?.time_window_end) {
          const [stHrs, stMin] = val.time_window_start.split(":").map(Number);
          const [enHrs, enMin] = val.time_window_end.split(":").map(Number);

          if (stHrs <= enHrs) {
            //Hours
            if (stHrs == enHrs) {
              // if hours equal and min greater
              if (stMin < enMin) {
                return true;
              } else {
                return errMsg;
              }
            }
            return true;
          } else {
            return errMsg;
          }
        } else return "Required";
      }
      return true;
    },
  },
};
</script>
<style lang="sass" scoped>

.actionBtn
  display: flex
  gag:0.5rem !important
  .danger
    background: red


:deep .v-data-table__wrapper
  .v-data-table-header
    th
      padding: 0rem 0.5rem
      height: 30px !important
      span
        font-size: 11px !important
  tbody
    td
      padding: 0rem 0.5rem !important
      height: 5rem !important
      .v-input, label
        font-size: 11px




:deep .v-list-item__content
  .v-list-item__title
    font-size: 11px !important

.gap
  gap: 0.5rem

.select-options
  width:100%
  display: flex
  justify-content: space-between
  text-transform: uppercase
  font-size: 11px !important

.timeWindow-clear
  display: flex
  align-items: center
  cursor: pointer
  font-weight: 600
</style>
